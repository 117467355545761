import nativeBridge from '@shared/utils/native-bridge';
import { OUTGOING_EVENTS } from '@shared/utils/native-bridge/constants';
import { store } from '@/store';
import { createStorage } from '@shared/services/storage';
import { PRIVATE_MESSAGE_TYPES } from '@shared/services/ws/config';
import { ws } from '@shared/services/ws';

const PAYMENT_STATUSES = {
  INITIALIZED: 'initialized',
};

class IndrivePaymentProvider {
  async init() {
    // Set up WebSocket listener for payment status updates
    ws.on(PRIVATE_MESSAGE_TYPES.PAYMENT_STATUS, this._handlePaymentStatus);
  }

  /**
   * Process a payment
   * @param {Object} data - Payment data
   * @param {string} data.preorder_id - Preorder ID
   * @param {string} data.payment_id - Payment ID for Indrive payment
   * @param {string} data.deeplink - not used for Indrive payment
   * @returns {Promise<Object>} - Payment result
   */
  async pay(payload) {
    if (!payload.payment_id || !payload.preorder_id) {
      throw new Error(
        'IndrivePaymentProvider: payment_id and preorder_id are required'
      );
    }

    try {
      await nativeBridge.pay(payload.payment_id);

      // Register event for payment started
      nativeBridge.registerOutgoingEvent(
        OUTGOING_EVENTS.ON_PAYMENT_STARTED,
        () => this._handlePaymentStarted(payload.preorder_id)
      );

      return Promise.resolve();
    } catch (error) {
      console.error('IndrivePaymentProvider: Payment failed', error);
      return Promise.reject();
    }
  }

  /**
   * Handle payment started event
   * @private
   * @param {string} preorderId - Preorder ID
   */
  _handlePaymentStarted(preorderId) {
    const storage = createStorage(window.localStorage);

    store.dispatch('UPDATE_APP_LOADER', true);

    // Store pending payment information
    storage.set(
      'payment::pending',
      JSON.stringify({
        preorderId,
        started_at: new Date().toISOString(),
      })
    );
  }

  /**
   * Handle payment status update from WebSocket
   * @private
   * @param {Object} data - Payment status data
   * @param {string} data.preorder_id - Preorder ID for payment
   * @param {string} data.payment_status - Payment status
   * @param {string} data.payment_method - Payment method
   * @param {string} data.error_code - Error code
   * @param {string} data.error_message - Error message
   */
  _handlePaymentStatus(data) {
    const storage = createStorage(window.localStorage);
    const pendingPayment = storage.get('payment::pending');

    if (!pendingPayment) return;

    if (pendingPayment) {
      store.dispatch('UPDATE_APP_LOADER', true);
    }

    if (
      data &&
      data.payment_status !== PAYMENT_STATUSES.INITIALIZED &&
      data.preorder_id === pendingPayment.preorderId
    ) {
      storage.remove('payment::pending');
      store.dispatch('UPDATE_APP_LOADER', false);
    }
  }
}

export default IndrivePaymentProvider;
