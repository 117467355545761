import { WebApp } from '@shared/WebApp';

class WebPaymentProvider {
  async init() {
    // No initialization needed for web provider
  }

  /**
   * Process a payment
   * @param {Object} payload - Payment payload
   * @param {string} payload.backlink - Payment link
   * @param {string} payload.successUrl - URL to redirect on success
   * @param {string} payload.failureUrl - URL to redirect on failure
   * @returns {Promise<Object>} - Payment result
   */
  async pay(payload) {
    return new Promise(resolve => {
      WebApp.pay(payload);
      return resolve();
    });
  }
}

export default WebPaymentProvider;
