import { DevConfigs } from '@/utils/dev-config';
import nativeBridge from '@shared/utils/native-bridge';
import DevMiniAppPaymentProvider from './providers/DevMiniAppPaymentProvider';
import DevWebPaymentProvider from './providers/DevWebPaymentProvider';
import { appDetection } from '@shared/services/app-detection';
import ChocoPaymentProvider from './providers/ChocoPaymentProvider';
import IndrivePaymentProvider from './providers/IndrivePaymentProvider';
import WebPaymentProvider from './providers/WebPaymentProvider';

export class PaymentService {
  constructor() {
    this.paymentProvider = this.resolveProvider();
  }

  resolveProvider() {
    if (DevConfigs.isDev) {
      return nativeBridge.isWebView()
        ? new DevMiniAppPaymentProvider()
        : new DevWebPaymentProvider();
    }

    if (appDetection.isChoco) {
      return new ChocoPaymentProvider();
    }

    if (appDetection.isIndrive) {
      return new IndrivePaymentProvider();
    }

    return new WebPaymentProvider();
  }

  init() {
    this.paymentProvider.init();
  }

  pay(payload) {
    return this.paymentProvider.pay(payload);
  }
}

const paymentService = new PaymentService();
export default paymentService;
