<!-- eslint-disable max-len -->
<template>
  <div
    class="py-4 flex items-start cursor-pointer md:hover:bg-sirius-gray-200 md:rounded-3xl"
    @click="goToProduct"
  >
    <div
      class="w-15 h-15 shrink-0 bg-sirius-gray-200 rounded-2xl cursor-pointer"
      @click="goToProduct"
    >
      <ProductPhoto
        :src="product.path"
        img-size="s:60:60"
        :blured="checkIsImgBlured"
        mix-blend
      />
    </div>

    <div class="ml-4 w-full">
      <div class="mb-2 flex items-start">
        <p
          class="pt-0.5 w-full text-sm leading-tight cursor-pointer"
          @click="goToProduct"
        >
          {{ product.name }}
        </p>
        <div
          v-if="product.status"
          class="ml-4 px-2 py-1 rounded-lg font-medium text-xs text-sirius-red-200 bg-sirius-red-200 bg-opacity-10"
          style="min-width: fit-content"
        >
          {{ product.status }}
        </div>
      </div>
      <p v-if="product.feature_name" class="mb-3 text-sirius-gray-700 text-sm">
        выбрано:
        <span class="ml-1 text-sirius-black-100">
          {{ product.feature_name }}
        </span>
      </p>
      <div class="flex items-center font-medium text-sirius-gray-700">
        <p class=" ">
          <span v-if="isDiscount" class="mr-1 line-through">
            {{ formatPrice(product.original_price) }} ₸
          </span>
          <span :class="{ 'text-sirius-red-200': isDiscount }">
            {{ formatPrice(product.price) }} ₸/шт
          </span>
        </p>
        <p class="ml-1">x {{ product.quantity }}</p>
        <p class="ml-3 text-sirius-black-100 font-bold flex">
          {{ totalPrice }} ₸
          <LoyaltyPercent
            v-if="!product.status"
            :percent="isOrderWithLoyalty ? 1 : 0"
            class="ml-1"
          />
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { AdultsService } from '@services/adults';
import { ProductService } from '@services/product';
import { formatPrice } from '@shared/utils/formatters/price';
import ProductPhoto from '@pure-ui/components/Product/ProductPhoto/ProductPhoto.vue';
import { isCoffeeAvailable } from '@/lib/coffee/is-coffee-available';
import LoyaltyPercent from '@/shared/components/loyalty/LoyaltyPercent/LoyaltyPercent.vue';

export default {
  name: 'OrderProduct',
  components: {
    ProductPhoto,
    LoyaltyPercent,
  },
  inject: ['bottomSheet'],
  props: {
    product: {
      type: Object,
      required: true,
    },
    isOrderFinished: {
      type: Boolean,
      default: false,
    },
    isOrderWithLoyalty: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapGetters(['isDesktop']),
    totalPrice() {
      return formatPrice(this.product.price * this.product.quantity);
    },
    isDiscount() {
      return this.product.original_price !== this.product.price;
    },
    checkIsImgBlured() {
      return (
        AdultsService.checkIsAdultProduct(this.product) &&
        !(
          AdultsService.checkIsAgreed() ||
          this.$store.getters['adults/isAgreed']
        )
      );
    },
  },
  methods: {
    formatPrice,
    goToProduct() {
      if (this.product.is_sample) return;
      if (this.product.is_coffee && !isCoffeeAvailable()) return;
      // Тут работать не будет — в эндпоинте заказа для товаров
      // не возвращаются категории 1 и 2 уровней.
      // Может позже доделаем
      let query = ProductService.buildQueryOnGoToProduct(this.product);

      if (this.isDesktop) {
        this.$router.push({
          name: 'product-full',
          params: {
            id: this.product.product_id.toString(),
          },
          query,
        });
      } else {
        this.$eventBus.emit('eb_open_product_details_modal', {
          id: this.product.product_id,
        });
      }
    },
  },
};
</script>
