/**
 * Это типы сообщений которые передаются в центрифуге по приватному каналу(userId).
 *
 * По этим параметрам определяем какие данные к какой фиче соответсвуют
 */
export const PRIVATE_MESSAGE_TYPES = {
  /** Для статусов заказа */
  ORDER_STATUS: 1,
  /** Для платежей indrive */
  PAYMENT_STATUS: 'indrive.payment.event',
};

/**
 * Это типы сообщений которые передаются в центрифуге по публичному каналу.
 *
 * По этим параметрам определяем какие данные к какой фиче соответсвуют
 */
export const PUBLIC_MESSAGE_TYPES = {
  /** Для СУРЖа */
  SURGE: 'warehouse.min.amount',
  /** Для повышенной платной доставки */
  HIGH_PRICE: 'high.delivery.costs',
  /** Для временного закрытия склада */
  CLOSURE_REASON: 'warehouse.polygon.closure',
};
