import nativeBridge from '@shared/utils/native-bridge';

class ChocoPaymentProvider {
  async init() {
    // No initialization needed for choco provider
  }

  /**
   * Process a payment
   * @param {Object} payload - Payment payload
   * @param {string} payload.deeplink - Deeplink for payment
   * @returns {Promise<String>} - Payment result
   */
  async pay(payload) {
    if (!payload.deeplink) {
      throw new Error('ChocoPaymentProvider: deeplink is required');
    }

    return nativeBridge.pay(payload.deeplink).then(res => {
      return Promise.resolve(res);
    });
  }
}

export default ChocoPaymentProvider;
