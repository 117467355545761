<template>
  <div
    class="SamplingPhoto"
    :class="`--${size} --sticker-${stickerSize} ${disabled ? '__disabled' : ''}
      ${isDefaultView ? '__default-view' : ''}
      ${withBorder ? '_with-border' : ''}`"
  >
    <div class="SamplingPhoto__image">
      <ProductPhoto :src="src" />
    </div>

    <div
      v-if="sticker"
      class="SamplingPhoto__present"
      :class="`--${stickerPosition}`"
    />
  </div>
</template>

<script>
import ProductPhoto from '@pure-ui/components/Product/ProductPhoto/ProductPhoto.vue';

export default {
  name: 'SamplingPhoto',
  components: {
    ProductPhoto,
  },
  props: {
    price: {
      type: Number,
      default: 1,
    },
    src: {
      type: String,
      default: '',
    },
    size: {
      type: String,
      default: 'sm',
    },
    stickerSize: {
      type: String,
      default: 'sm',
    },
    isDefaultView: {
      type: Boolean,
      default: false,
    },
    withBorder: {
      type: Boolean,
      default: false,
    },
    stickerPosition: {
      type: String,
      default: 'bottom-right',
      validator: value => ['bottom-right', 'top-left'].includes(value),
    },
    disabled: Boolean,
    sticker: Boolean,
  },
};
</script>

<style scoped>
.SamplingPhoto {
  position: relative;
  background-color: #ffeccc;
}
.SamplingPhoto._with-border {
  border: 0.5px solid rgba(0, 0, 0, 0.1);
}

.SamplingPhoto.__default-view {
  @apply bg-sirius-gray-200;
}

.SamplingPhoto,
.SamplingPhoto__image {
  border-radius: 0.75rem;
}

.SamplingPhoto__image :deep(.product-photo-img) {
  border-radius: 0;
}

.SamplingPhoto.__disabled .SamplingPhoto__image {
  filter: grayscale(1);
}
.SamplingPhoto__image {
  overflow: hidden;
  width: 100%;
  height: 100%;
  /* border: 0.5px solid rgba(0, 0, 0, 0.1); */
}
.--sm.SamplingPhoto {
  max-width: 60px;
  min-width: 60px;
  max-height: 60px;
  min-height: 60px;
  height: 60px;
  border-radius: 12px;
}
.--sm.__default-view.SamplingPhoto {
  max-width: 48px;
  min-width: 48px;
  max-height: 48px;
  min-height: 48px;
  height: 48px;
  border-radius: 16px;
}
.--sm .SamplingPhoto__image {
  max-width: 60px;
  min-width: 60px;
  max-height: 60px;
  min-height: 60px;
  height: 60px;
  border-radius: 12px;
  border: none;
}
.--sm.__default-view .SamplingPhoto__image {
  max-width: 48px;
  min-width: 48px;
  max-height: 48px;
  min-height: 48px;
  height: 48px;
  border-radius: 16px;
}
.--regular.SamplingPhoto {
  max-width: 60px;
  min-width: 60px;
  max-height: 60px;
  min-height: 60px;
  height: 60px;
}
.--regular .SamplingPhoto__image {
  max-width: 58px;
  min-width: 58px;
  max-height: 58px;
  min-height: 58px;
  height: 58px;
}
.--md.SamplingPhoto {
  max-width: 72px;
  min-width: 72px;
  max-height: 72px;
  min-height: 72px;
  height: 72px;
}
.--md .SamplingPhoto__image {
  max-width: 70px;
  min-width: 70px;
  max-height: 70px;
  min-height: 70px;
  height: 70px;
}
.--lg.SamplingPhoto {
  max-width: 100px;
  min-width: 100px;
  max-height: 100px;
  min-height: 100px;
  height: 100px;
  border-radius: 28px;
}
.--lg .SamplingPhoto__image {
  max-width: 98px;
  min-width: 98px;
  max-height: 98px;
  min-height: 98px;
  height: 98px;
  border-radius: 28px;
}
.--xl.SamplingPhoto {
  max-width: 140px;
  min-width: 140px;
  max-height: 140px;
  min-height: 140px;
  height: 140px;
  border-radius: 40px;
}
.--xl .SamplingPhoto__image {
  max-width: 140px;
  min-width: 140px;
  max-height: 140px;
  min-height: 140px;
  height: 140px;
  border-radius: 40px;
}

.SamplingPhoto__present {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;

  color: white;
  font-weight: 700;
  text-align: center;

  width: 100%;
  height: 100%;

  background-image: url('@img/products/present-box-sticker.png');
  background-position: center center;
  background-repeat: no-repeat;
  background-size: contain;
}
.__disabled .SamplingPhoto__present {
  background-image: url('@img/products/present-box-sticker-disabled.png');
}
.--sticker-xs .SamplingPhoto__present {
  max-width: 24px;
  min-width: 24px;
  max-height: 24px;
  min-height: 24px;
}
.--sticker-xs .--bottom-right.SamplingPhoto__present {
  top: auto;
  left: auto;
  bottom: -5px;
  right: -5px;
}
.--sticker-sm .SamplingPhoto__present {
  bottom: -5px;
  right: -5px;
  max-width: 24px;
  min-width: 24px;
  max-height: 24px;
  min-height: 24px;

  font-size: 10.667px;
  line-height: 12px;
  letter-spacing: 0.053px;
}
.--sticker-regular .SamplingPhoto__present {
  max-width: 32px;
  min-width: 32px;
  max-height: 32px;
  min-height: 32px;
  right: 3px;
  bottom: 2px;
}
.--top-left.SamplingPhoto__present {
  top: 4px;
  left: 4px;
  bottom: auto;
  right: auto;
}
.--sticker-sm .--bottom-right.SamplingPhoto__present {
  top: auto;
  left: auto;
  bottom: 10px;
  right: 6px;
}
.--sticker-md .SamplingPhoto__present {
  bottom: 0;
  right: -19px;
  max-width: 52px;
  min-width: 52px;
  max-height: 52px;
  min-height: 52px;

  font-size: 17.333px;
  line-height: 19.5px;
  letter-spacing: 0.087px;
}
.--sticker-lg .SamplingPhoto__present {
  bottom: 6px;
  right: -16px;

  max-width: 40px;
  min-width: 40px;
  max-height: 40px;
  min-height: 40px;

  font-size: 18.667px;
  line-height: 21px;
  letter-spacing: 0.093px;
}
.--sticker-xl .SamplingPhoto__present {
  bottom: 20px;
  right: -24px;
  max-width: 48px;
  min-width: 48px;
  max-height: 48px;
  min-height: 48px;

  font-size: 26.667px;
  line-height: 30px;
  letter-spacing: 0.133px;
}
</style>
