<template>
  <div class="product-list-mobile">
    <div class="pl-4 pr-4 pt-3 md:p-0 flex flex-col gap-3">
      <SamplingPromoCard
        v-for="(sample, index) in products"
        :key="`${index}_${sample.id}`"
        :sample="sample"
        is-horizontal
      />
    </div>
  </div>
</template>

<script>
import SamplingPromoCard from '@components/samplings/SamplingPromoCard.vue';

export default {
  name: 'SamplingPromoFullWidth',
  components: {
    SamplingPromoCard,
  },
  props: {
    products: {
      type: Array,
      default: () => [],
    },
  },
};
</script>
