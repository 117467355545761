<template>
  <div class="AddressMapContainer">
    <slot
      v-bind="{
        coords: coords,
        loading: loading,
        savedAddressResults: savedAddressResults,

        isShown: isShown,
        isExtended: isExtended,
        noDeliveryType: noDeliveryType,
        addressValidation: addressValidation,
        searchedAddresses: searchedAddresses,

        onInput: onInput,
        onClear: onClear,
        saveAnyway: saveAnyway,
        onLocationUpdated: onLocationUpdated,
        chooseOtherAddress: chooseOtherAddress,
        selectSavedAddress: selectSavedAddress,
        searchAddressClick: searchAddressClick,
        onNoDeliveryToAddress: onNoDeliveryToAddress,
      }"
    >
    </slot>

    <CityNotSameModal
      v-model="cityNotSameModal"
      :city-name="selectedAddress ? selectedAddress.cityName : ''"
      @onChange="onChangeCity"
      @onRight="onRightCity"
    />
  </div>
</template>
<script>
import debounce from 'debounce';
import { mapGetters } from 'vuex';
import { MapService } from '@services/map';
import { CityService } from '@services/city';
import { AddressService } from '@services/address';
import { Analitycs, EVENTS } from '@shared/services/analitycs';

import CityNotSameModal from '@pure-ui/components/Address/CityNotSameModal/CityNotSameModal.vue';
import throttle from '@/utils/functions/throttle';
import { SentryService } from '@services/sentry';

export default {
  name: 'AddressMapContainer',
  components: {
    CityNotSameModal,
  },
  inject: ['toast'],
  data() {
    return {
      geocode: '',
      coords: null,

      savedAddressResults: [],

      isSearched: false,
      searchResults: [],
      loading: false,

      hasBuilding: false,
      hasStreet: false,

      selectedAddress: null,

      cityNotSameModal: false,
      isNoDeliveryToAddress: false,
    };
  },
  computed: {
    ...mapGetters('user', ['addresses']),
    isExtended() {
      return this.selectedAddress && this.selectedAddress.isInExtended;
    },
    isValidAddress() {
      return this.hasStreet && this.hasBuilding;
    },
    isInDeliveryZone() {
      if (!this.selectedAddress) return true;
      if (this.selectedAddress.from === 'afterCitySameModal') return true;
      return (
        this.selectedAddress.isInTerritory || this.selectedAddress.isInExtended
      );
    },
    noDeliveryType() {
      if (this.selectedAddress?.from === 'afterCitySameModal') return '';
      if (!this.isValidAddress) return '';
      if (!this.isInDeliveryZone && this.selectedAddress.isInCity)
        return 'NearToDelivery';
      if (!this.isInDeliveryZone && !this.selectedAddress.isInCity)
        return 'FarFromDelivery';
      return '';
    },
    addressValidation() {
      let hasNumbers = !!/.*[, ][0-9].*/.test(this.geocode);
      if (!this.isSearched) return '';
      if (!this.isValidAddress && !hasNumbers) return 'notFullAddress';
      if (!this.isInDeliveryZone) return 'nodelivery';
      if (this.searchResults.length === 0 && this.isNoDeliveryToAddress)
        return 'isNoDeliveryToAddress';
      if (this.searchResults.length === 0) return 'nothingFound';
      return '';
    },
    searchedAddresses() {
      return this.searchResults.map(address => {
        let description = address.GeoObject.description.replace(
          ', Казахстан',
          ''
        );
        if (description.includes(`, ${address.cityName}`)) {
          description = description.replace(`, ${address.cityName}`, '');
          description = address.cityName + ', ' + description;
        }
        return {
          ...address,
          name: address.GeoObject.name,
          description: description,
        };
      });
    },
    isShown() {
      return (
        this.loading ||
        this.savedAddressResults.length ||
        this.addressValidation ||
        this.noDeliveryType ||
        this.searchedAddresses.length
      );
    },
  },
  methods: {
    sendSelectedAddress(address) {
      address = address ?? { GeoObject: { name: '' } };
      let isCorrectAddress = false;
      if (address) {
        isCorrectAddress =
          address.hasStreet &&
          address.hasBuilding &&
          address.isInCity &&
          (address.isInTerritory || address.isInExtended);
      }
      this.$emit('getGeoObject', { address, isCorrectAddress });
    },
    resetSearchingStates() {
      this.geocode = '';
      this.isSearched = false;
      this.searchResults = [];
      this.savedAddressResults = [];
      this.hasStreet = false;
      this.hasBuilding = false;
    },
    onClear() {
      this.resetSearchingStates();
      this.selectedAddress = null;
      this.sendSelectedAddress();
    },
    isAnywaySavedAddressSameAsNewAddress(collection) {
      return (
        collection?.featureMember?.[0]?.GeoObject?.name &&
        this.selectedAddress &&
        this.selectedAddress.from === 'saveAnyway' &&
        collection.featureMember[0].GeoObject.name ===
          this.selectedAddress.GeoObject.name
      );
    },
    checkIsSameCityAsCurrent(address) {
      let currentSelectedCityName = CityService.getCityData().name;
      if (
        this.selectedAddress &&
        this.selectedAddress.from === 'afterCitySameModal'
      )
        return true;

      if (currentSelectedCityName !== address.cityName) {
        this.selectedAddress = address;
        this.selectedAddress.from = 'afterCitySameModal';
        this.openCityNotSameModal();
        return false;
      }
      return true;
    },
    onSelectAddress(address) {
      this.$emit('onAddressSelected', address);
      this.resetSearchingStates();
      this.coords = address.GeoObject.Point.pos.split(' ').reverse();
      this.sendSelectedAddress(address);
    },
    searchAddressClick(address) {
      if (!address) return;
      if (!this.checkIsSameCityAsCurrent(address)) {
        return;
      }

      this.selectedAddress = address;
      this.selectedAddress.from = 'afterSearch';
      // if (!this.isInDeliveryZone || !address.isInCity) return;

      this.onSelectAddress(address);
    },
    pinError: throttle(
      function () {
        this.toast.show(this.$t('placePinOnBuilding'), 'error');
      },
      3000,
      { trailing: false }
    ),
    onLocationUpdated(collection) {
      if (!collection) return;

      if (this.isAnywaySavedAddressSameAsNewAddress(collection)) return;

      this.selectedAddress = null;
      this.resetSearchingStates();

      MapService.validateGeoObject(collection).then(res => {
        this.selectedAddress = res.address;
        this.selectedAddress.from = 'map';

        this.geocode = res.address.GeoObject.name;
        this.hasBuilding = res.hasBuilding;
        this.hasStreet = res.hasStreet;

        this.sendSelectedAddress(res.address);

        if (!this.isValidAddress) {
          this.pinError();
        } else if (!this.isInDeliveryZone) {
          this.$emit(
            'onNotInDeliveryZoneAfterUpdateLocation',
            this.selectedAddress.GeoObject?.name || ''
          );
        }
      });
    },
    saveAnyway() {
      this.selectedAddress.from = 'saveAnyway';
      this.selectedAddress.isInTerritory = true;
      this.onSelectAddress(this.selectedAddress);
      this.$emit('onActionEnd');
    },
    onInput: debounce(function onInput(value) {
      value = value.replace('Астана,', ' ').replace('Алматы,', ' ');
      value = value.trim();
      this.resetSearchingStates();
      this.selectedAddress = null;
      if (value === '') return;

      this.geocode = value;
      this.$emit('onUpdateGeocode', value);

      this.savedAddressResults = MapService.searchAddressFromSavedByQuery(
        value,
        this.addresses
      );

      this.loading = true;
      MapService.findAddressByQuery(this.geocode)
        .then(res => {
          this.hasStreet = res.hasStreet;
          this.hasBuilding = res.hasBuilding;
          this.searchResults = res.addresses;
          this.isNoDeliveryToAddress = res.isNoDeliveryToAddress;
          this.isSearched = true;
        })
        .catch(err => {
          SentryService.captureError(err, 'Address Map Container');
        })
        .finally(() => {
          this.loading = false;
          this.isSearched = true;
        });
    }, 1600),
    chooseOtherAddress() {
      this.resetSearchingStates();
      this.selectedAddress = null;
      this.sendSelectedAddress();
      this.$emit('onFocusInput');
    },
    onNoDeliveryToAddress() {
      if (!this.selectedAddress) return;
      if (this.isInDeliveryZone) return;
      const { GeoObject } = this.selectedAddress;
      if (!GeoObject) return;

      let payload = {
        city: GeoObject.description,
        street: GeoObject?.name ? GeoObject.name : '',
        lat: GeoObject.Point.pos.split(' ')[1],
        long: GeoObject.Point.pos.split(' ')[0],
        isInCity: this.selectedAddress.isInCity,
      };
      Analitycs.logEvent(EVENTS.NO_DELIVERY_TO_ADDRESS, payload);
    },
    selectSavedAddress(address) {
      AddressService.changeAddress(address).catch(err => {
        this.toast.show(err.message, 'error');
      });
      this.$emit('onSavedAddressSelected', address);
    },
    closeCityNotSameModal() {
      this.cityNotSameModal = false;
    },
    openCityNotSameModal() {
      this.cityNotSameModal = true;
    },
    onChangeCity() {
      this.closeCityNotSameModal();
    },
    onRightCity() {
      this.closeCityNotSameModal();
      this.onSelectAddress(this.selectedAddress);
    },
    // AccessFromOutside
    defineDefaults() {
      this.coords = CityService.getCityData().defaultCoords;
    },
    // AccessFromOutside
    defineWithCoords(coords) {
      this.selectedAddress = { from: 'withCoords' };
      this.coords = coords;
    },
  },
  i18n: {
    messages: {
      ru: {
        placePinOnBuilding: 'Расположите указатель на дом или&nbsp;здание',
      },
      kk: {
        placePinOnBuilding: 'Нұсқаулықты үй немесе ғимараттың үстіне қойыңыз',
      },
    },
  },
};
</script>
