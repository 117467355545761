<template>
  <DeliveryOption
    :value="everyProductRejected ? false : enabledSamplingDelivery"
    :option="{
      icon: 'cart-sampling-option',
      title: $t('title'),
      descr: outOfStock ? $t('outOfStock') : '',
    }"
    :disabled="outOfStock"
    @change="onChange"
    @click="$emit('click')"
  >
    <div
      v-if="enabledSamplingDelivery && !outOfStock && !everyProductRejected"
      class="mt-4 flex flex-col gap-5"
    >
      <SamplingProductWrapper
        v-for="product in productsWithoutRejected"
        :key="product.id"
        :product="product"
      />
    </div>
  </DeliveryOption>
</template>

<script>
import { RahmetApp } from '@shared/RahmetApp';
import { mapGetters, mapState } from 'vuex';
import DeliveryOption from '@pure-ui/components/DeliveryOption/DeliveryOption.vue';
import SamplingProductWrapper from '@components/samplings/SamplingProductWrapper.vue';
import { SamplingsService } from '@services/samplings';

export default {
  name: 'SamplingDeliveryOption',
  components: {
    DeliveryOption,
    SamplingProductWrapper,
  },
  props: {
    outOfStock: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapState('samplings', ['enabledSamplingDelivery', 'rejectedSampleIds']),
    ...mapGetters('samplings', ['sampleProducts', 'everyProductRejected']),
    productsWithoutRejected() {
      return this.sampleProducts?.filter(
        product => !this.rejectedSampleIds.includes(product.id)
      );
    },
  },
  methods: {
    saveStateOfToggle(value) {
      if (value) {
        SamplingsService.setRejectedSampleIds([]);
      }
      this.$store.commit('samplings/SET_ENABLED_SAMPLING_DELIVERY', value);
    },
    onChange(value) {
      console.log('onChange: ', value);
      RahmetApp.hapticSelection();
      this.saveStateOfToggle(value);
    },
  },
  i18n: {
    messages: {
      kk: {
        title: 'Сыйлыққа берілетін тауар',
        outOfStock: 'Уақытша қолжетімсіз',
      },
      ru: {
        title: 'Подарочный товар',
        outOfStock: 'Временно нет в наличии',
      },
    },
  },
};
</script>

<style>
.add-text {
  @apply ml-9 mr-14 mt-0.5 text-sm leading-4 text-sirius-gray-700;
  letter-spacing: 0.07px;
}

.cart-sampling-option__disabled {
  @apply text-sirius-gray-1000 text-sm;
}
</style>
