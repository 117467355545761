import { appDetection } from '@shared/services/app-detection';

export default {
  install(VueLocal) {
    Object.defineProperties(VueLocal.prototype, {
      $isChoco: {
        get() {
          return appDetection.isChoco;
        },
      },
      $isIndrive: {
        get() {
          return appDetection.isIndrive;
        },
      },
    });
  },
};
