<template>
  <div class="sampling-product-list">
    <div class="flex gap-3 md:w-full">
      <SamplingPromoCard
        v-for="(sample, index) in products"
        :key="`${index}_${sample.id}`"
        :sample="sample"
        :size="size"
        :is-horizontal="$isDesktop"
        class="ml-2 md:ml-0 shrink-0 md:flex-1"
      />
    </div>
  </div>
</template>

<script>
import SamplingPromoCard from '@components/samplings/SamplingPromoCard.vue';

export default {
  name: 'SamplingPromoListGrid',
  components: {
    SamplingPromoCard,
  },
  props: {
    products: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    size() {
      if (this.products?.length === 2) {
        return 'sm';
      } else if (this.products?.length === 3) {
        return 'xs';
      }
      return 'normal';
    },
  },
};
</script>

<style scoped>
.sampling-product-list {
  @apply pl-2 pr-4 pt-3;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  justify-content: center;
}

@media (min-width: 768px) {
  .sampling-product-list {
    @apply flex gap-3 p-0 justify-start;
  }
}
</style>
