<template>
  <div v-if="show">
    <SamplingPromoListWrapper :navigation="null" :products="activeSamplings">
      <template #title>
        {{ $tc('title', activeSamplings?.length || 0) }}
      </template>
    </SamplingPromoListWrapper>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import SamplingPromoListWrapper from '@/components/samplings/SamplingPromoListWrapper.vue';

export default {
  name: 'SamplingCategory',
  components: {
    SamplingPromoListWrapper,
  },
  computed: {
    ...mapState('samplings', ['activeSamplings']),
    show() {
      return !!this.activeSamplings?.length;
    },
  },
  i18n: {
    messages: {
      ru: {
        title:
          'Подарочный товар | Подарочный товар | Подарочные товары | Подарочные товары',
      },
      kk: {
        title:
          'Сыйлыққа берілетін тауар | Сыйлыққа берілетін тауар | Сыйлыққа берілетін тауар | Сыйлыққа берілетін тауар',
      },
    },
  },
};
</script>
