<template>
  <AddressMapContainer
    ref="AddressMapContainerRef"
    v-slot="{
      coords,
      loading,
      savedAddressResults,

      noDeliveryType,
      addressValidation,
      searchedAddresses,

      onInput,
      onClear,
      saveAnyway,
      onLocationUpdated,
      chooseOtherAddress,
      selectSavedAddress,
      searchAddressClick,
      onNoDeliveryToAddress,
    }"
    class="AddressMap h-full"
    @onFocusInput="onFocusInput"
    @getGeoObject="$emit('getGeoObject', $event)"
    @onSavedAddressSelected="returnToMain"
    @onAddressSelected="closeSearchListModal"
    @onUpdateGeocode="onUpdateGeocode"
    @onNotInDeliveryZoneAfterUpdateLocation="v => openSearchListModal(v, true)"
  >
    <div class="h-full bg-white">
      <RMap
        ref="RMapRef"
        geo-location-btn
        :map-offset="0.006"
        :input-coords="coords"
        @onActionEnd="$emit('onActionEnd')"
        @actionBegin="$emit('actionBegin')"
        @onSelectLocation="onLocationUpdated"
        @onMapInit="onMapInited"
      />
    </div>

    <!-- Модалка ввода улицы и дома -->
    <SearchListModal
      ref="searchListModal"
      v-model="showModal"
      :autofocus="autofocusEnabled"
      :street="streetName"
      :no-delivery="noDeliveryType"
      :loading="loading"
      :message-type="addressValidation"
      :saved-addresses="savedAddressResults"
      :searched-addresses="searchedAddresses"
      @update:street="v => ((streetName = v), onInput(v))"
      @typeNew="chooseOtherAddress"
      @goToMain="returnToMain"
      @saveAnyway="saveAnyway"
      @onClear="onClear"
      @onSelectSavedAddress="selectSavedAddress"
      @onReturnToMap="closeSearchListModal(), $emit('actionBegin')"
      @onSelectSearchedAddress="searchAddressClick"
      @onNoDeliveryToAddress="onNoDeliveryToAddress"
      @update:modelValue="onUpdateModal"
    />

    <RequestCityModal
      v-model="cityModal"
      :cities="cities"
      @onSelect="onCitySelect"
      @onClose="onCloseCityModal"
    />
    <RequestGeoModal
      v-model="geoModal"
      @onClose="onCloseGeo"
      @onAllow="onGeoAllow"
    />
  </AddressMapContainer>
</template>

<script>
import { GeoService } from '@services/geo';
import { CityService } from '@services/city';
import nativeBridge from '@shared/utils/native-bridge';
import { CITIES } from '@services/city/constants';

import RequestCityModal from '@pure-ui/components/Address/RequestCityModal/RequestCityModal.vue';
import SearchListModal from '@pure-ui/components/Address/SearchListModal/SearchListModal.vue';
import RequestGeoModal from '@pure-ui/components/Address/RequestGeoModal/RequestGeoModal.vue';
import AddressMapContainer from '@components/address/AddressMapContainer.vue';
import RMap from '@shared/components/map/RMapV2.vue';

export default {
  name: 'AddressMap',
  components: {
    AddressMapContainer,
    RequestCityModal,
    RequestGeoModal,
    SearchListModal,
    RMap,
  },
  inject: ['toast'],
  props: {
    /**
     * Адрес, на котором тапнули Редактировать
     */
    inputCoords: {
      type: Array,
      default: null,
    },
  },
  data() {
    return {
      geoAllowed: false,
      showModal: false,
      streetName: '',
      autofocusEnabled: true,

      // нужен для отлова момента когда он вернулся после настройек
      onGeoAllowing: false,

      cityModal: false,
      geoModal: false,
      cities: Object.values(CITIES),
    };
  },
  methods: {
    onUpdateModal(value) {
      if (!value) this.$emit('onActionEnd');
    },
    onUpdateGeocode(geocode) {
      this.streetName = geocode;
    },
    onFocusInput() {
      this.$refs.searchListModal.focusInput();
    },
    /**
     * Открывает попап с вводом адреса и здания.
     * Вызывается в двух случаях:
     * 1. при тапе на "Улица, здание/дом" в форме
     * 2. если на карте тапнули на адрес, который
     *    не входит в зону доставки
     */
    openSearchListModal(street = null, isNoDelivery = false) {
      console.log('openSearchListModal', isNoDelivery);

      this.autofocusEnabled = !isNoDelivery;
      if (street) this.streetName = street;
      this.showModal = true;
      this.$emit('actionBegin');
    },
    // доступен снаружи
    closeSearchListModal() {
      this.showModal = false;
      this.$emit('onActionEnd');
    },
    returnToMain() {
      this.$router.push({ name: 'main' });
    },

    closeCityModal() {
      this.cityModal = false;
    },
    openCityModal() {
      this.cityModal = true;
    },
    onCloseCityModal() {
      this.$log('onCloseCityModal');
      let ref = this.$refs.AddressMapContainerRef;
      ref.defineDefaults();
      localStorage.setItem('city::is-city-variants-modal-shown', true);
    },
    onCitySelect(city) {
      this.$log('onCitySelect', { city });
      CityService.setCity(city.id);
      this.closeCityModal();
      localStorage.setItem('city::is-city-variants-modal-shown', true);
    },

    closeGeoModal() {
      this.geoModal = false;
    },
    openGeoModal() {
      this.geoModal = true;
    },
    onGeoAllow() {
      this.$log('onGeoAllow');
      localStorage.setItem('geo::is-geo-modal-shown', true);
      this.onGeoAllowing = true;
      this.closeGeoModal();
      nativeBridge.openAppSettings();
      this.geoAllowed = true;
    },
    onCloseGeo() {
      if (this.geoAllowed) return;
      localStorage.setItem('geo::is-geo-modal-shown', true);
      this.closeGeoModal();
      this.openCityModal();
    },

    checkIsGeoModalCanOpen() {
      let hasMethod = nativeBridge.hasMethod('openAppSettings');
      if (!hasMethod) return false;

      let isShown = Boolean(localStorage.getItem('geo::is-geo-modal-shown'));
      return !isShown;
    },
    checkIsCityModalCanOpen() {
      let isShown = Boolean(
        localStorage.getItem('city::is-city-variants-modal-shown')
      );
      return !isShown;
    },
    async onMapInited() {
      this.$emit('onMapInit');
      this.$log('AddressMap onMapInited', { inputCoords: this.inputCoords });

      if (this.inputCoords?.length === 2) {
        this.$refs.AddressMapContainerRef.defineWithCoords(this.inputCoords);
        return;
      }

      let status = await GeoService.requestGeoPermission();
      if (status === 'granted') {
        this.$refs.RMapRef.defineGeolocation(true);
        return;
      }

      let canOpenGeoModal = this.checkIsGeoModalCanOpen();
      let canOpenCityModal = this.checkIsCityModalCanOpen();

      if (canOpenGeoModal) {
        this.openGeoModal();
        return;
      }

      if (canOpenCityModal) {
        this.openCityModal();
        return;
      }

      this.$refs.RMapRef.defineDefaults(true);
    },
    async defineGeoLocationAfterReconnect() {
      let status = await GeoService.requestGeoPermission();
      if (status === 'granted') this.$refs.RMapRef.defineGeolocation(true);
      else this.openCityModal();
    },
  },
  eventBusEvents: {
    eb_on_reconnect_to_app() {
      this.$log('eb_on_reconnect_to_app', null, 'EventBus');
      if (this.onGeoAllowing) {
        this.onGeoAllowing = false;
        this.defineGeoLocationAfterReconnect();
      }
    },
  },
  i18n: {
    messages: {
      ru: {},
      kk: {},
    },
  },
};
</script>
