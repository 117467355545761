<template>
  <MainLayout>
    <UiRippleLoader v-if="!product" class="mt-16 mx-auto" />
    <RenderlessProduct
      v-else
      v-slot="{
        replaceFromFull,
        removeFromCart,
        isSelected,
        addToCart,
        isPending,
        quantity,
        addToFavorites,
        removeFromFavorites,
        isFavorite,
        showBrandName,
        hasActiveSampling,
      }"
      :product="product"
      :feature="feature"
    >
      <div class="container">
        <UiBreadCrumbs class="mt-16" :links="breadCrumbs" />

        <div class="mt-8 flex justify-between max-w-screen-xl">
          <div
            class="w-full flex items-start justify-center relative"
            style="max-width: 588px"
          >
            <UiImageSlider
              :images="imageSrc"
              style="width: 480px; height: 480px"
            />
          </div>
          <div class="px-12 w-full" style="max-width: 536px">
            <ProductLabels
              v-if="getLabels(hasActiveSampling)"
              :labels="getLabels(hasActiveSampling)"
              type="wrap"
              full
            />

            <h1 class="mt-3 font-bold text-4xl leading-10">
              {{ $i18n.locale === 'kk' ? product.name_kk : product.name }}
            </h1>

            <h1
              v-if="showBrandName"
              class="font-bold text-4xl leading-10 text-sirius-gray-1000 line-clamp-1 break-words"
            >
              {{ product.brand_name }}
            </h1>

            <div class="flex items-center mt-7">
              <div class="product-old-price">
                <SalePrice
                  v-if="oldPrice"
                  :old-price="formatPrice(oldPrice)"
                  :price="price"
                  size="xl"
                />
                <template v-else> {{ price }} ₸ </template>
              </div>

              <div
                v-if="features && productWeight && features.length"
                class="ml-2 px-2 font-medium leading-5 text-sirius-gray-100"
              >
                {{ productWeight }}
              </div>
            </div>

            <div v-if="features && features.length > 1" class="mt-9">
              <UiDivider />
              <p class="mt-8 font-medium text-2xl leading-tight">
                {{ $t('selectWeight') }}
              </p>

              <div class="flex flex-wrap mt-6">
                <FeatureTag
                  v-for="item in features"
                  :key="item.id"
                  :item="item"
                  :active="feature.id === item.id"
                  class="mr-3 mb-3"
                  @onClick="onTagTapped(item, isSelected)"
                />
              </div>
            </div>

            <div class="mt-10 w-full">
              <div class="flex items-center space-x-3">
                <UiButton v-if="isAuthorized && isPending" size="LG">
                  <UiLoader size="lg" variant="white" />
                </UiButton>
                <UiButton
                  v-else-if="!maxQuantity"
                  variant="gray"
                  size="LG"
                  disabled
                >
                  <span class="px-1">{{ $t('outOfStock') }} </span>
                </UiButton>
                <UiButton
                  v-else-if="
                    product.replacement === true &&
                    product.replacement.replacement
                  "
                  size="LG"
                  @click="onReplaceProduct(replaceFromFull)"
                >
                  <span class="px-1">{{ $t('toReplace') }}</span>
                </UiButton>
                <ProductCounter
                  v-else-if="quantity"
                  :model-value="quantity"
                  :max="maxQuantity"
                  size="xl"
                  @onIncrement="onIncrement(addToCart)"
                  @onDecrement="onDecrement(removeFromCart)"
                  @onMaxLimit="onMaxLimit"
                >
                  <p class="pl-1">{{ quantity }} x {{ price }} ₸</p>
                  <p
                    v-if="maxQuantity > 0 && quantity === maxQuantity"
                    class="max-limit pr-1"
                  >
                    {{ $t('maxLimit') }}
                  </p>
                </ProductCounter>
                <UiButton v-else size="LG" @click="onAddToCart(addToCart)">
                  <span class="pl-1">{{ $t('toAddToCart') }}</span>
                  <span class="ml-auto pr-1">{{ price }} ₸</span>
                </UiButton>

                <SaveBtn
                  variant="secondary"
                  :saved="isFavorite"
                  @onSave="addToFavorites(product.id)"
                  @onRemove="removeFromFavorites(product.id)"
                />
              </div>

              <div
                class="product-bottom-text"
                :class="{ hidden: cartSize === 0 }"
              >
                {{
                  minAmountReminder
                    ? $t('untilMinimumOrder', {
                        amount: formatPrice(minAmountReminder),
                      })
                    : $t('amountOfProductsInCart', {
                        count: cartSize,
                        unit: productDeclination,
                        price: formatPrice(cartTotalPriceWithDiscount),
                      })
                }}
              </div>
            </div>

            <div
              v-if="
                formatedAppliances &&
                formatedAppliances.max_quantity &&
                formatedAppliances.price
              "
              ref="appliancesReminder"
              class="AppliancesReminder"
            >
              <UiDivider />

              <div class="mt-7 flex items-center font-medium">
                <IconInfo class="mr-3" />
                <!-- eslint-disable vue/no-v-html -->
                <p
                  class="leading-4 text-sirius-gray-700"
                  v-html="$t('disposableAppliancesAreNotIncluded')"
                />
                <!-- eslint-enable vue/no-v-html -->
              </div>
              <p class="font-bold text-lg mt-9">
                {{ $t('toAddDisposableAppliances') }}
              </p>
              <HorizontalProductCardWrapper
                class="mt-6"
                :product="formatedAppliances"
                :added-from="PRODUCT_ADDED.PRODUCT"
              />
            </div>

            <template v-if="infoList.length">
              <UiDivider class="mt-11 mb-8" />

              <h2 class="mt-8 font-medium text-2xl mb-6">
                {{ $t('specifications') }}
              </h2>

              <ProductInfo
                v-for="({ title, value }, index) in infoList"
                :key="index"
                :title="$t(title)"
                :value="`${value}`"
                class="mt-4"
              />
              <ProductInfo
                v-if="nutritionals && nutritionals.shelf_life"
                :title="$t('shelfLife')"
                :value="localizeShelfLife(nutritionals.shelf_life)"
                class="mt-4"
              />
              <ProductInfo
                v-if="productWeight && product.weight_visible"
                :title="$t('productWeight')"
                :value="productWeight"
                class="mt-4"
              />
            </template>

            <template v-if="hasDescription">
              <UiDivider class="my-7" />

              <ProductInfo
                :title="$t('description')"
                class="flex-col space-y-2"
              >
                <template #value>
                  <p class="mt-2" v-html="description"></p>
                </template>
              </ProductInfo>
            </template>
          </div>
        </div>
      </div>
    </RenderlessProduct>
  </MainLayout>
</template>

<script>
import { mapGetters } from 'vuex';
import nativeBridge from '@shared/utils/native-bridge';
import { formatPrice } from '@/utils/formatters/price';
import { productFull } from '@shared/components/product/product-full';
import { RenderlessProduct } from '@shared/components/product/RenderlessProduct';
import { getProductInfoList } from '@shared/components/product/lib/product-info';
import ProductInfo from '@pure-ui/components/Product/ProductModal/components/ProductInfo.vue';
import ProductCounter from '@pure-ui/components/Product/ProductCounter/ProductCounter.vue';
import MainLayout from '@shared/components/layout/MainLayout.vue';
import UiButton from '@pure-ui/components/UiButton/UiButton.vue';
import UiLoader from '@pure-ui/components/UiLoader/UiLoader.vue';
import UiRippleLoader from '@ui/UiRippleLoader.vue';
import UiBreadCrumbs from '@ui/UiBreadCrumbs.vue';
import UiImageSlider from '@ui/UiImageSlider.vue';
import UiDivider from '@ui/UiDivider.vue';

export default {
  name: 'ProductFullView',
  components: {
    RenderlessProduct,
    UiRippleLoader,
    ProductCounter,
    UiBreadCrumbs,
    UiImageSlider,
    ProductInfo,
    MainLayout,
    UiDivider,
    UiButton,
    UiLoader,
    HorizontalProductCardWrapper: () =>
      import('@shared/components/product/HorizontalProductCardWrapper.vue'),
    ProductLabels: () =>
      import('@pure-ui/components/Product/ProductLabels/ProductLabels.vue'),
    SalePrice: () =>
      import(
        '@pure-ui/components/Product/ProductCard/components/SalePrice.vue'
      ),
    SaveBtn: () => import('@pure-ui/components/Product/SaveBtn/SaveBtn.vue'),
    FeatureTag: () => import('@shared/components/product/FeatureTag.vue'),
    IconInfo: () => import('@shared/components/icons/IconInfo.vue'),
  },
  mixins: [productFull],
  async beforeRouteUpdate(to, from, next) {
    await next();
    this.initProduct();
  },
  props: {
    id: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      loading: false,
      IconPresentBox: new URL('@img/products/present-box.png', import.meta.url)
        .href,
    };
  },
  computed: {
    ...mapGetters('delivery', ['warehouseId']),
    ...mapGetters(['isAuthorized']),
    breadCrumbs() {
      const links = [{ name: 'Главная', path: '/' }];
      if (this.query && this.query.path && this.query.category_name) {
        links.push({
          name: decodeURIComponent(this.query.category_name),
          path: this.query.path,
        });
      }
      if (this.product) {
        links.push({ name: this.product.name, path: this.$route.fullPath });
      }
      return links;
    },
    infoList() {
      return getProductInfoList(this.product);
    },
    description() {
      return this.$i18n.locale === 'kk'
        ? this.product.full_description_kk
        : this.product.full_description;
    },
    sampleLabel() {
      return {
        icon: this.IconPresentBox,
        name: this.$t('present'),
        color: '#FF8A00',
      };
    },
  },
  watch: {
    $isDesktop(newVal) {
      if (newVal === false) {
        this.webviewDeeplink();
      }
    },
    warehouseId(newVal, oldVal) {
      if (newVal !== oldVal) {
        this.initProduct();
      }
    },
  },
  created() {
    if (!this.$isDesktop) this.webviewDeeplink();
    else this.initProduct();
  },
  methods: {
    formatPrice,
    initProduct() {
      this.loading = true;
      this.resetState();
      this.productId = this.$route.params.id;
      if (!this.productId) {
        this.loading = false;
        return;
      }
      /**
       * todo: По идее можно удалить, сразу в computed сюда обратиться
       */
      this.query = this.$route.query;
      this.fetchProduct();
    },
    webviewDeeplink() {
      let productId = this.$route.params.id;
      this.$nextTick(() => {
        this.$eventBus.emit('eb_open_product_details_modal', {
          id: productId,
        });
      });
      this.$router.push({
        name: 'main',
        query: { productDeeplink: true, productId: productId },
      });
    },
    localizeShelfLife(value) {
      let num = '';
      let text = '';
      if (!Number.isNaN(+value)) {
        num = +value;
        text = 'месяц';
      } else {
        let splited = value.split(' ');
        num = splited[0] || '';
        text = splited[1] || '';
      }
      if (!text && !num) return '';

      if (text.includes('дн') || text.includes('день')) {
        return this.$tc('dayPlural', num);
      }
      if (text.includes('мес')) {
        return this.$tc('monthPlural', num);
      }
      if (text.includes('лет') || text.includes('год')) {
        return this.$tc('yearPlural', num);
      }
      return num + text;
    },
    onMaxLimit() {
      nativeBridge.hapticNotification('error');
    },
    getLabels(hasActiveSampling) {
      const list = [];

      if (hasActiveSampling) {
        list.push(this.sampleLabel);
      }

      if (this.product.labels?.length) {
        list.push(...this.product.labels);
      }

      return list;
    },
  },
  i18n: {
    messages: {
      ru: {
        maxLimit: 'Больше нет',
        brand: 'Бренд',
        selectWeight: 'Выберите вес',
        mainPage: 'Главная',
        available: 'в наличии {amount} шт.',
        availableYet: 'в наличии ещё {amount} шт.',
        toReplace: 'Заменить',
        toAddToCart: 'Добавить в корзину',
        outOfStock: 'Нет в наличии',
        untilMinimumOrder: 'До минимального заказа {amount} ₸',
        amountOfProductsInCart: 'В корзине {count} {unit} на {price} ₸',
        disposableAppliancesAreNotIncluded:
          'Одноразовые приборы не&nbsp;прилагаются к&nbsp;блюду',
        toAddDisposableAppliances: 'Добавить одноразовые приборы?',
        specifications: 'Характеристики',
        calories: 'Калории',
        proteins: 'Белки',
        fats: 'Жиры',
        carbohydrates: 'Углеводы',
        shelfLife: 'Срок хранения',
        productWeight: 'Вес товара',
        description: 'Описание',
        vitamins: 'Витамины',
        manufacturer: 'Производитель',
        lactelQazaqstan: 'Lactel, Казахстан',
        country: 'Страна',
        color: 'Цвет',
        sugar: 'Сахар',
        strength: 'Крепость',
        dayPlural: '0 дней | {n} день | {n} дня | {n} дней',
        monthPlural: '0 месяцев | {n} месяц | {n} месяца | {n} месяцев',
        yearPlural: '0 лет | {n} год | {n} года | {n} лет',
        present: 'подарок к этому товару',
      },
      kk: {
        maxLimit: 'Қалмады',
        brand: 'Бренд',
        selectWeight: 'Салмақ таңдаңыз',
        mainPage: 'Бастапқы',
        available: '{amount} шт. бар',
        availableYet: 'тағы {amount} шт. бар',
        toReplace: 'Ауыстыру',
        toAddToCart: 'Себетке салу',
        outOfStock: 'Қазір жоқ',
        untilMinimumOrder: 'Төменгі шекті тапсырысқа дейін {amount} ₸',
        amountOfProductsInCart: 'Себетте {price} ₸-ге {count} тауар',
        disposableAppliancesAreNotIncluded:
          'Бір реттік ыдыстар тағамға қосылмайды',
        toAddDisposableAppliances: 'Бір реттік ыдыстарды салу қажет пе?',
        specifications: 'Сипаттамалар',
        calories: 'Калориялар',
        proteins: 'Ақуыздар',
        fats: 'Майлар',
        carbohydrates: 'Көмірсулар',
        shelfLife: 'Сақтау мерзімі',
        productWeight: 'Тауар салмағы',
        description: 'Сипаттама',
        vitamins: 'Дәрумендер',
        manufacturer: 'Өндіруші',
        lactelQazaqstan: 'Lactel, Қазақстан',
        country: 'Елі',
        color: 'Түсі',
        sugar: 'Қант түрі',
        strength: 'Күштілігі',
        dayPlural: '{n} күн',
        monthPlural: '{n} ай',
        yearPlural: '{n} жыл',
        present: 'осы тауарға сыйлық беріледі',
      },
    },
  },
};
</script>

<style scoped>
.max-limit {
  @apply text-sm font-medium leading-4;
  opacity: 0.7;
}
.product-bottom-text {
  @apply mt-3 mb-2 text-sirius-gray-1000
  text-base font-normal leading-6;
}
.product-old-price {
  @apply px-5 py-2 bg-sirius-gray-200 rounded-2xl
    text-2xl font-bold leading-30;
}
</style>
