<template>
  <div class="MBlockFreeDeliveryNotApplied" v-on="$listeners">
    <div class="MBlockFreeDeliveryNotApplied__row flex pt-3">
      <UiInlineIcon name="promocode" class="text-sirius-gray-900" />
      <div class="ml-3 mr-auto flex flex-col">
        <span>{{ promocode }}</span>
      </div>
      <UiInlineIcon name="tooltip" style="color: #a4a2b7" />
      <UiInlineIcon
        class="MBlockFreeDeliveryNotApplied__iconErr"
        name="arrow-right"
        style="color: #a4a2b7"
      />
    </div>
    <div
      class="MBlockFreeDeliveryNotApplied__msg pb-5"
      v-html="$t('freeDeliveryNotApplied')"
    />
  </div>
</template>

<script>
import UiInlineIcon from '@pure-ui/components/UiInlineIcon/UiInlineIcon.vue';

export default {
  name: 'MBlockFreeDeliveryNotApplied',
  components: {
    UiInlineIcon,
  },
  props: {
    promocode: {
      type: String,
      default: '',
    },
  },
  i18n: {
    messages: {
      ru: {
        freeDeliveryNotApplied: `
          Промокод не&nbsp;применён, так как в&nbsp;заказе
          уже&nbsp;действуют условия бесплатной доставки
        `,
      },
      kk: {
        promocodeApplied: `
          Промокод қолданылмады, себебі бұл тапсырыста
          жеткізу тегін шарты бар.
        `,
      },
    },
  },
};
</script>

<style scoped>
.MBlockFreeDeliveryNotApplied__row {
  padding-bottom: 8px;
}

.MBlockFreeDeliveryNotApplied__iconErr {
  margin-left: 6px;
  margin-right: -6px;
}

.MBlockFreeDeliveryNotApplied__msg {
  color: #7b7a8c;
  letter-spacing: 0.08px;
  line-height: 18px;
}
</style>
